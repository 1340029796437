























import Vue from 'vue'
import { Component, Prop, Watch, Mixins } from 'vue-property-decorator'
import GetStoreMixin from '@/mixins/GetStore'
import { documentsNamespace } from '@store/documents'
import { userNamespace } from '@store/user'
import { DocumentsActionTypes } from '@store/documents/Types'
import { IProject } from '@store/user/Interface'
import { UserActionTypes } from '@store/user/Types'

@Component
export default class FormConfirmation extends Mixins(GetStoreMixin) {
  @userNamespace.State('selectedProject')
  private selectedProjectId!: number

  @documentsNamespace.Action(DocumentsActionTypes.A_MAKE_LIST_WORKS)
  private makeAListOfWorks!: (projectId) => Promise<IProject>

  @userNamespace.Action(UserActionTypes.A_FETCH_USER)
  private fetchUser!: () => Promise<void>

  private isDisabled: boolean = false

  private addListWork() {
    this.isDisabled = true
    this.makeAListOfWorks(this.selectedProjectId).then(() => {
      this.isDisabled = false
      this.$emit('confirm')
      this.fetchUser()
    })
  }
}
